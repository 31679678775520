@import "modules/responsive-type.scss";
@import url('https://fonts.googleapis.com/css?family=Miltonian+Tattoo|Oldenburg');
$primary: #b31e54;

/* MAIN COLOR */

$secondary: #f1a421;

/* SECONDARY COLOR */

$blk: #333;

/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */

$wht: #fafafa;
$border-radius: 0px;

/* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */

$footerLinks: #fafafa;
// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;
// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;
// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 
$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop
html,
body {
    height: 100%;
}

html {
    @include fluid-type($min_width,
    $max_width,
    $min_font,
    $max_font);
}

p,
ol,
ul {
    font-size: 1rem;
    font-family: 'Oldenburg', cursive;
}

a {
    font-size: 1rem;
    font-family: 'Miltonian Tattoo', cursive;
}

h1 {
    font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem;
    @include fluid-type($min_width,
    $max_width,
    $mod_1*$mod_1*$mod_1 *$min_font,
    $mod_2*$mod_2*$mod_2 *$min_font);
    font-family: 'Miltonian Tattoo', cursive;
}

h2 {
    font-size: $mod_1*$mod_1*$mod_1 *1rem;
    @include fluid-type($min_width,
    $max_width,
    $mod_1*$mod_1*$mod_1 *$min_font,
    $mod_2*$mod_2*$mod_2 *$min_font);
    font-family: 'Miltonian Tattoo', cursive;
}

h3 {
    font-size: $mod_1*$mod_1 *1rem;
    @include fluid-type($min_width,
    $max_width,
    $mod_1*$mod_1 *$min_font,
    $mod_2*$mod_2 *$min_font);
    font-family: 'Miltonian Tattoo', cursive;
}

.container {
    @media (max-width: 1440px) {
        width: 100% !important;
    }
    @media (max-width: 1024px) {
        width: 100% !important;
    }
    @media (max-width: 767px) {
        width: 100% !important;
    }
}

.top-pad {
    @media (max-width: 767px) {
        margin-top: 100px;
    }
}

.flash {
    display: none;
}

body {
    background: url('../img/backdrop-bg.png');
    background-repeat: repeat;
    background-attachment: fixed;
}

.wrapper {
    padding-left: 240px !important;
    @media (max-width: 767px) {
        padding-left: 0px !important;
    }
}

.sticky-wrapper,
.is-sticky {
    height: auto !important;
}

.navbar {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,b31e54+100 */
    background: #ffffff;
    /* Old browsers */
    background: -moz-linear-gradient(top, #ffffff 0%, #b31e54 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #ffffff 0%, #b31e54 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #ffffff 0%, #b31e54 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    margin: 0px;
}

q,
blockquote {
    quotes: "“" "”";
}

// .navbarFixed {
//     position: fixed;
//     width: 100%;
//     top: 0;
//     z-index: 999;
// }

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #fff;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

.navbar-brand {
    background: url('../img/logo.png');
    background-repeat: no-repeat;
    margin-top: 20px;
    width: 220px;
    height: 220px;
    margin-left: 5px;
    @media (max-width: 767px) {
        background: url('../img/logo-small.png');
        background-repeat: no-repeat;
        width: 220px;
        height: 70px;
        margin-top: 5px;
    }
}

.navbar-nav {
    margin-top: 225px;
    // text-align: left;
    float: none;
    @media (max-width: 767px) {
        text-align: center;
        margin-top: 0px;
    }
}

.navbar-fixed-left {
    width: 240px !important;
    position: fixed;
    border-radius: 0;
    height: 100%;
    background-color: white;
    border: 0px;
    box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.2);
    background-color: $blk;
    border-right: 2px solid $wht;
    @media (max-width: 767px) {
        width: 100% !important;
        height: 75px;
        position: fixed;
        border-radius: 0;
        background-color: white;
        border: 0px;
        box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.2);
        background-color: #333;
        margin-top: 0px;
        z-index: 1;
    }
}

.navbar-right {
    float: none;
    margin-right: 0px;
    @media (max-width: 767px) {
        margin-right: -15px;
    }
}

.navbar-toggle {
    margin: 18px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
    @media (max-width: 388px) {
        margin-top: 14px;
    }
    @media (max-width: 767px) {
        padding: 13px;
    }
}

.navbar-fixed-left .navbar-nav>li {
    float: none;
    /* Cancel default li float: left */
    width: 240px;
    border-bottom: 1px solid $wht;
    @media (max-width: 767px) {
        text-align: center;
        height: 45px;
        display: inline-block;
        align-items: center;
        width: 100%;
    }
}

.navbar-fixed-left .navbar-nav>li>a {
    text-align: center;
    margin-top: 0px;
    display: flex;
    align-items: center;
    color: $wht;
    @media (max-width: 767px) {
        margin-top: 0;
        padding: 6px 0px;
        display: inline-block;
    }
    &:focus,
    &:active {
        background: transparent;
        color: $wht;
        outline: 0;
    }
    &:hover {
        background: transparent;
        color: $secondary;
        @media (max-width: 1024px) {
            background: transparent;
            color: $blk;
            outline: 0;
        }
    }
}

.navbar-collapse {
    width: 100%;
    //   margin-top: 18px;
    background: transparent;
    padding-right: 0px;
    padding-left: 0px;
    @media (max-width: 767px) {
        border-bottom: 2px solid $primary;
        padding-left: 15px;
        padding-right: 15px;
        background-color: $secondary;
    }
}


/* On using dropdown menu (To right shift popuped) */

.navbar-fixed-left .navbar-nav>li>.dropdown-menu {
    margin-top: -50px;
    margin-left: 140px;
}

.navbar-header {
    height: 63px;
}


/* ========= BUTTONS ============ */

.btn-download,
.btn-download:focus,
.btn-download:active,
.btn-download:visited {
    background-color: $primary;
    border-color: $wht;
    color: $wht;
    padding: 10px 20px;
    text-transform: uppercase;
    border-radius: 5px;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
}

.btn-download:hover {
    background-color: $blk;
    color: $wht;
}

.btn-download {
    margin-bottom: 15px !important;
    display: block;
    margin: 0 auto;
    max-width: 175px;
    margin-top: 15px;
}


/* =========== HEADER ========== */

.header {
    background-image: url('../img/banner.jpg');
    background-repeat: no-repeat;
    background-position: 80% 50%;
    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;
    padding: 275px 100px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    @media (max-width: 1440px) {
        background-position: 60% 20%;
        padding: 10px 10px;
    }
    @media (max-width: 1199px) {
        background-position: 80% 20%;
        padding: 10px 10px;
    }
    @media (max-width: 1024px) {
        background-position: 85% 20%;
        padding: 10px 10px;
    }
}

.header-overlay {
    background-color: rgba(31, 31, 31, 0.65);
    padding: 50px 0px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border: 2px solid $secondary;
    @media (max-width: 767px) {
        margin-top: 76px;
        padding: 26px;
        border: 1px solid #fff;
    }
}

.header h1 {
    color: $wht;
}

.header p {
    color: $wht;
}

.header img {
    width: 100%;
    display: block;
    margin: 0px auto;
    max-width: 350px;
    @media (max-width: 1024px) {
        max-width: 250px;
    }
}

.header .col-md-6 {
    width: 50%;
    @media (max-width: 1280px) {
        width: 100%;
    }
}


/* =========== SECTION-TWO ============ */

.section-two {
    padding: 50px 0px;
}

.section-two h1 {
    color: $primary;
}

.section-two h3 {
    color: $primary;
    text-shadow: 1px 1px $blk;
}

.section-two img {
    width: 100%;
    display: block;
    margin: 0px auto;
    max-width: 800px;
    margin-top: 25px;
    @media(max-width: 1024px) {
        margin-top: 25px;
    }
}


/* =========== SECTION-THREE ========== */

.section-three {
    background-image: url('../img/lower-banner.jpg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;
    padding: 275px 100px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    @media (max-width: 1440px) {
        background-position: 60% 20%;
        padding: 10px 10px;
    }
    @media (max-width: 1199px) {
        background-position: 80% 20%;
        padding: 10px 10px;
    }
    @media (max-width: 1199px) {
        background-position: 70% 20%;
        padding: 10px 10px;
    }
}

.section-three-overlay {
    background-color: rgba(31, 31, 31, 0.65);
    padding: 50px 0px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border: 2px solid $secondary;
    @media (max-width: 768px) {
        padding: 26px;
        border: 1px solid #fff;
    }
}

.section-three h1 {
    color: $wht;
}

.section-three p {
    color: $wht;
}

.section-three img {
    width: 100%;
    display: block;
    margin: 0px auto;
    max-width: 350px;
    @media (max-width: 1024px) {
        max-width: 250px;
    }
}

.section-three .col-md-6 {
    width: 50%;
    @media (max-width: 1280px) {
        width: 100%;
    }
}


/* ============= SECTION-FOUR ============= */

.section-four {
    padding: 50px 0px;
}

.section-four .spacer {
    margin-bottom: 25px;
}

.section-four h1 {
    color: $primary;
    margin-top: 0px;
    @media (max-width: 767px) {
        margin-top: 25px;
    }
}

.section-four img {
    width: 100%;
    display: block;
    margin: 0px auto;
    max-width: 800px;
    @media(max-width: 1024px) {
        margin-top: 25px;
    }
}


/* ============ BUTTONS MODALS ============= */

@mixin btn1 {
    text-align: center;
    margin: 0 auto;
    border: 1px solid $primary;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    z-index: 1000;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    input {
        border: none;
        border-bottom: 1px solid darken($primary, 20%);
        text-align: center;
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn1;
        display: block;
        width: 75%;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

footer {
    padding: 50px 0px 20px;
    background: $blk;
    color: $footerLinks;
    a {
        color: $footerLinks;
        &:hover {
            color: lighten($footerLinks, 10%);
        }
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}


/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

// input#username,
// input#password {
//     width: 100%;
//     @media (max-width: 1024px) {
//         font-size: 16px;
//     }
// }
// select {
//     -moz-appearance: none;
//     text-indent: 0.01px;
//     text-overflow: '';
// }
// a,
// a:hover,
// a:focus,
// a:active {
//     outline: 0 !important;
// }
.top-pad {
    padding: 3em;
    background: white;
    @media (max-width: 990px) {
        padding: 1em;
    }
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */
    }
}